.header {
    position: fixed;
    width: 100%;
    margin: 0;
    padding: 0;
    background-color: #fff;
    transition: 0.5s;
    z-index: 4;

    &__content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 3px 0;

        .left-side {
            margin-top: -15px;
            width: calc((100% - 140px) / 2);
        }

        .right-side {
            margin-top: -15px;
            width: calc((100% - 140px) / 2);
            text-align: right;
        }

        .middle {
            width: 100px;
            margin: 0 $spacing;
            transition: width 0.5s;
            text-align: center;

            img {
                width: 50px;
                transition: width 0.5s;
            }
        }
    }

    .top-link {
        font-size: 20px;
        margin: 0 $spacing-half;
        padding: 5px $spacing-half;
        border-bottom-width: 5px;
        border-bottom-style: solid;
        text-decoration: none;
        background-size: 100% 200%;
        transition: 0.5s;

        &.-blue-bottom-border {
            border-bottom-color: $color-brand-1;
            background-image: linear-gradient(to bottom, #fff 50%, $color-brand-1 50%);

            &:hover, &:active, &:focus, &.active {
                color: #fff;
                border-bottom-color: darken($color-brand-1, 20%);
                background-position: 0 100%;
            }
        }

        &.-orange-bottom-border {
            border-bottom-color: $color-brand-3;
            background-image: linear-gradient(to bottom, #fff 50%, $color-brand-3 50%);

            &:hover, &:active, &:focus, &.active {
                color: #fff;
                border-bottom-color: darken($color-brand-3, 20%);
                background-position: 0 100%;
            }
        }

        &.-purple-bottom-border {
            border-bottom-color: $color-brand-5;
            background-image: linear-gradient(to bottom, #fff 50%, $color-brand-5 50%);

            &:hover, &:active, &:focus, &.active {
                color: #fff;
                border-bottom-color: darken($color-brand-5, 20%);
                background-position: 0 100%;
            }
        }
    }

    &.is-scrolled {
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.75);
        z-index: $zindex-header;

        .left-side, .right-side {
            width: calc((100% - 140px) / 2);
        }

        .middle {
            width: 100px;

            img {
                width: 40px;
            }
        }
    }

    @include screen-sm() {
        &__content {
            align-items: flex-start;
            justify-content: center;
            flex-wrap: wrap;
        }

        .middle {
            order: 0;
            width: 100%;
            text-align: center;
            margin-bottom: $spacing;
        }

        .left-side {
            order: 1;
            width: auto;
            margin: 0 0 5px 0;
            text-align: right;
        }

        .right-side {
            order: 2;
            width: auto;
            margin: 0 0 5px 0;
            text-align: left;
        }

        &.is-scrolled {
            .left-side, .right-side {
                width: auto;
            }

            .middle {
                width: 100%;
            }
        }
        .dissapear {
            display: none;
        }
    }

    @include screen-xs() {
        .top-link {
            margin: 0 5px;
            padding: 0;
        }

        .container {
            width: 100%;
            padding: 0;
        }

        .middle {
            margin: 0;
        }
        .dissapear {
            display: none;
        }
    }

}

body.is-loading header.is-scrolled {
    position: absolute;
}
