.person {
    position: relative;
    width: 150px;
    height: auto;
    margin: $spacing $spacing-half 0 $spacing-half;
    text-align: center;
    padding: $spacing-half;
    transition: background-color 0.5s;

    &__image {
        width: 85px;
        height: 85px;
        object-fit: cover;
        border-radius: 100%;
        transition: box-shadow 0.5s;
    }

    &__languages {
        margin: 0 0 $spacing-half 0;
        /* display: flex; */
        position: absolute;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;

        .sprite-container {
            width: 27.5px;
            height: 13px;
            margin: 3px 0;
        }

        .sprite {
            transform: scale(0.4, 0.4) translate(-50%, -50%);
        }
    }

    h4 {
        font-size: 16px;
        text-align: center;
        padding: 0;
        margin: 0 0 5px 0;
        font-weight: $font-bold;
    }

    p {
        display: inline;
        margin: 0;
        padding: 0;
    }

    i {
        display: none;
        color: $color-font-base;
        padding: 0 7px;
        cursor: pointer;
    }

    a {
        display: inline-block;
        margin: 5px $spacing-half 0;
        color: $color-brand-1;
    }

    &.--c1:hover {
        background-color: lighten($color-brand-1, 30%);
    }

    &.--c2:hover {
        background-color: lighten($color-brand-2, 45%);
    }

    &.--c3:hover {
        background-color: lighten($color-brand-3, 35%);
    }

    &.--c4:hover {
        background-color: lighten($color-brand-4, 45%);
    }

    &.--c5:hover {
        background-color: lighten($color-brand-5, 45%);
    }

    &:hover {
        .person__image {
            box-shadow: 2px 2px 7px 0 rgba(0, 0, 0, 0.75);
        }

        i {
            display: inline-block;
        }
    }

    &.js-user-link {
        cursor: pointer;
    }

    @include screen-xs() {
        margin: $spacing 0 0 0;
        width: 150px !important;
    }
}

.person-contact {
    position: relative;
    width: 100%;
    height: auto;
    text-align: -webkit-center;
    padding: $spacing-half;
    transition: background-color 0.5s;
    cursor: pointer;
    margin: 6px;

    &__image {
        width: 75px;
        height: 75px;
        object-fit: cover;
        border-radius: 100%;
        transition: box-shadow 0.5s;
        vertical-align: initial;

    }

    &__languages {
        margin: -5px 0 $spacing-half 0;
        position: absolute;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        left: 10px;

        .sprite-container {
            width: 27.5px;
            height: 13px;
            margin: 3px 0;
        }

        .sprite {
            transform: scale(0.4, 0.4) translate(-50%, -50%);
        }
    }

    &__left {
        vertical-align: middle;
        width: 18%;
        display: table-cell;
        text-align: start;
    }

    &__right {
        vertical-align: middle;
        width: 60%;
        display: table-cell;
        text-align: left;
    }

    h4 {
        font-size: 18px;
        text-align: left;
        padding: 0;
        margin: 0 0 5px 0;
        font-weight: $font-bold;
    }

    p {
        display: inline;
        margin: 0;
        padding: 0;
    }

    i {
        display: none;
        color: $color-font-base;
        padding: 0 7px;
        cursor: pointer;
    }

    a {
        display: inline-block;
        margin: 5px $spacing-half 0;
        color: $color-brand-1;
    }

    &.--c1:hover {
        background-color: lighten($color-brand-1, 30%);
    }

    &.--c2:hover {
        background-color: lighten($color-brand-2, 45%);
    }

    &.--c3:hover {
        background-color: lighten($color-brand-3, 35%);
    }

    &.--c4:hover {
        background-color: lighten($color-brand-4, 45%);
    }

    &.--c5:hover {
        background-color: lighten($color-brand-5, 45%);
    }

    &:hover {
        .person__image {
            box-shadow: 2px 2px 7px 0 rgba(0, 0, 0, 0.75);
        }

        i {
            display: inline-block;
        }
    }

    &.js-user-link {
        cursor: pointer;
    }
    @include screen-sm() {
        &__left {width: 15%;}
        width: 98% !important;

        &__image {
            width: 50px;
            height: 50px;
        }
        &__languages {
            left: -10px;
        }
    }

    @include screen-xs() {
        margin: $spacing 0 0 0;
        width: 98% !important;

        h4 {
            font-size: 15px;
        }
        &__left {
            width: 21%;
        }
        p {
            font-size: 12px !important;
        }

        &__image {
            width: 50px;
            height: 50px;
        }
        &__languages {
            left: -10px;
        }
    }

    @include screen-xxs() {
        &__left {
            width: 35%;
        }
        &__languages {
            left: -10px;
        }
    }
}


.change-roles {
    width: 400px;
    max-width: 100%;
    border-radius: 0;
    border: 1px solid #636363;
    padding: $spacing-half;
    text-align: center;

    &__close {
        position: absolute;
        top: 0;
        right: 5px;
        font-size: 20px;
    }

    &__content {
        margin: $spacing 0;

        .select-box {
            ul {
                position: relative;
                top: 0;
            }
        }
    }

    h2 {
        font-size: 20px;
        text-align: center;
        font-weight: $font-bold;
        margin-bottom: $spacing;
    }
}
