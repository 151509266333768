.admin-popup {
    &__close {
        position: absolute;
        top: 0;
        right: 5px;
        font-size: 20px;
    }

    &__title {
        width: 100%;
        color: $color-brand-3;
        font-weight: $font-bold;
        font-size: 25px;
        text-align: center;
    }

    &__content {
        display: flex;
        justify-content: center;
        width: 100%;
        margin-top: $spacing-double;
    }
}

.menu-column {
    width: 25%;
    margin-right: $spacing-third;

    &__title {
        padding-bottom: $spacing-half;
        border-bottom: 3px solid black;
        color: $color-font-heading;
        font-weight: $font-bold;
        text-align: center;

        &--products {
            border-color: $color-brand-1;
        }

        &--finance {
            border-color: $color-brand-4;
        }
    }

    &__list {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    &:last-child {
        margin-right: 0;
    }
}

.column-link {
    position: relative;
    text-align: center;
    cursor: pointer;

    &:hover {
        & a {
            color: white;
            background-position: 0 100%;
        }

        & .continent-city__remove {
            opacity: 1;
        }
    }

    & a {
        display: block;
        width: 100%;
        margin: $spacing-half 0;
        padding: $spacing-half;
        transition: all 0.25s ease;
        background-color: transparent;
        background-size: 100% 200%;
        background-image: linear-gradient(to bottom, $color-modal-bg 50%, $color-brand-1 50%);
        text-decoration: none;
    }


    &--finance {
        a {
            background-image: linear-gradient(to bottom, $color-modal-bg 50%, $color-brand-4 50%);
        }
    }
}
