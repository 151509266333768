.join {
    padding: $spacing 0;
    text-align: center;

    h2 {
        padding: $spacing-half 0;
        font-weight: $font-bold;
    }

    p {
        margin-bottom: 0;
        font-size: 20px;
    }

    .btn {
        height: auto;
        white-space: normal;
    }

    .mail {
        font-weight: $font-bold;
    }
}
