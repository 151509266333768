.orders {
    padding: $spacing $spacing-half 0 $spacing-half;

    .flash-value {
        animation-name: anim;
        animation-duration: 1s;
        animation-iteration-count: 1;
        animation-direction: alternate;
        animation-timing-function: linear;
    }


    @keyframes anim {
        50% {
            background-color: #fffed4;
        }
    }
}
