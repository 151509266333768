.order {
    width: 800px;
    max-width: 100%;
    margin-bottom: $spacing-double;

    &__top-line {
        display: flex;
        align-items: center;
        padding: 5px 0;
        margin-bottom: $spacing-half;
        border-bottom: 2px solid $gray2;
        cursor: pointer;

        a {
            width: 20px;
            font-size: 20px;
            text-decoration: none;
        }

        h4, h5 {
            font-size: 16px;
            padding: 0;
            margin: 0;
        }

        h4 {
            width: 150px;
        }

        h5 {
            width: calc(100% - 170px);
            text-align: right;
        }
    }

    .order__info {
        padding: 7px;
    }

    &__item-title {
        color: #999;
    }

    &__qr {
        display: inline-block;
        width: 150px;
        border: 1px solid $gray1;
        padding: $spacing-half;
        border-radius: 5px;
        margin-bottom: $spacing-half;
    }

    &__category {
        display: none;
        margin-bottom: 20px;

        h2 {
            font-size: 16px;
            color: #999;
            margin: 0 0 $spacing-half 0;
            padding: 0 0 $spacing-half 0;
            border-bottom: 2px solid $gray2;
        }

        a {
            font-size: 16px;
            display: inline-block;
            margin-left: $spacing-half;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__items {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
    }

    &__item {
        width: 30%;
        display: flex;
        align-items: center;
        margin: 10px 10px;
        font-size: 16px;

        @media screen and (max-width: $small-width) {
            width: 90%;
        }
        @media screen and (max-width: $medium-width) and (min-width: 531px) {
            width: 44%;
        }

        .sprite-container {
            width: 37.5px;
            height: 23px;
            margin-right: $spacing-half;
        }

        .sprite {
            transform: scale(0.5, 0.5) translate(-50%, -50%);
        }

        .fa {
            margin: 0 $spacing-half;
        }

        strong {
            font-weight: $font-bold;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

    	.js-city-name {
    	    width: 40%;
    	}
    }

    .row {
        margin-bottom: $spacing-half;
    }

    .btc-price {
        display: inline-block;
        padding-bottom: 3px;
        border-bottom: 2px double #444;
    }

    .fa-caret-down {
        display: inline-block;
    }

    .fa-caret-up {
        display: none;
    }

    .btn-bnw {
        margin-top: $spacing;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
    }

    @include screen-xs() {
        .textFix {
            display: block;
            overflow-wrap: break-word;
        }
    }
    @include screen-sm() {
        .textFix {
            display: block;
            overflow-wrap: break-word;
        }
    }

}

.order.is-expanded {
    .order__category {
        display: block;
    }

    .fa-caret-down {
        display: none;
    }

    .fa-caret-up {
        display: inline-block;
    }

    .order__top-line {
        border: 0;
    }

    .order__info {
        padding: 5px;
        border: 2px solid $gray2;
    }
}

.edit-address {
    width: 400px;
    max-width: 100%;
    border-radius: 0;
    border: 1px solid #636363;
    padding: $spacing-half;
    text-align: center;

    &__close {
        position: absolute;
        top: 0;
        right: 5px;
        font-size: 20px;
    }

    &__content {
        margin: $spacing 0;
    }

    h2 {
        font-size: 20px;
        text-align: center;
        font-weight: $font-bold;
        margin-bottom: $spacing;
    }
}

#tracking-number {
    height: 50px;
    padding: 27px 10px 5px;
}
