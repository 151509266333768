.events-finance-manager {
    font-size: 14px;

    .load-more {
        position: relative;
        margin-top: $spacing-double;
        left: 50%;
        transform: translateX(-50%);
    }

    .header-row {
        width: 800px;
        max-width: 100%;
        font-size: 20px;
        margin-bottom: 30px;
    }
}


.print-area {
    display: none;
    width: 720px;
    margin-bottom: 30px;
    padding-bottom: 10px;
    background-color: #fff;
    box-sizing: inherit;

    * {
        background-color: #fff;
    }

    .hide-on-pdf {
        display: none !important;
    }

    .print-logo {
        display: block;
        width: 120px;
        height: 120px;
        margin: 0 auto 30px auto;
    }

    .document-title {
        text-align: center;
        font-size: 20px;
        font-weight: $font-bold;
        margin-bottom: 30px;
    }

    .print-row {
        display: flex;
        justify-content: space-between;
        margin-bottom: $spacing;
        padding: 0 $spacing $spacing $spacing;
        border-bottom: 2px solid $gray2;

        &__right {
            text-align: right;
        }

        &__left {
            text-align: left;
        }

        h4 {
            font-size: 16px;
            font-weight: $font-bold;
            margin: 0 0 5px 0;
            padding: 0;
        }
    }

    h5.btc-price {
        width: 100%;
        text-align: center;
    }
}

.print-area2 {
    width: 720px;
    margin-bottom: 30px;
    padding-bottom: 10px;
    background-color: #fff;
    box-sizing: inherit;

    * {
        background-color: #fff;
    }

    h1, h2 {
        text-decoration: underline;
    }

    .border {
        border: 1px solid black;
        text-align: center;
    }
    .aligned {
        text-align: center;
    }

    .hide-on-pdf {
        display: none !important;
    }

    .print-logo {
        display: block;
        width: 120px;
        height: 120px;
        margin: 0 auto 30px auto;
    }

    .document-title {
        text-align: center;
        font-size: 20px;
        font-weight: $font-bold;
        margin-bottom: 30px;
    }

    .print-row {
        display: flex;
        justify-content: space-between;
        margin-bottom: $spacing;
        padding: 0 $spacing $spacing $spacing;
        border-bottom: 2px solid $gray2;

        &__right {
            text-align: right;
        }

        &__left {
            text-align: left;
        }

        h4 {
            font-size: 16px;
            font-weight: $font-bold;
            margin: 0 0 5px 0;
            padding: 0;
        }
    }

    h5.btc-price {
        width: 100%;
        text-align: center;
    }
}

.new-payment {
    display: block;
    width: 800px;
    max-width: 100%;
    margin: 0 auto $spacing-double auto;

    &__row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: $spacing;

        &:first-child {
            padding-bottom: $spacing-half;
            border-bottom: 2px solid $gray2;
        }

        .form-group {
            width: 400px;
        }

        .btn {
            display: block;
            margin-left: $spacing;
        }


        .btn-green {
            background-color: transparent !important;
            color: #fff !important;
            background-size: 100% 200% !important;
            background-image: linear-gradient(to bottom, $color-brand-4 50%, darken($color-brand-4, 5%) 50%) !important;

            &:hover, &:focus, &:active {
                background-position: 0 100% !important;
                background-color: transparent !important;
                background-image: linear-gradient(to bottom, $color-brand-4 50%, darken($color-brand-4, 5%) 50%) !important;
                outline: none !important;
            }
        }

        @include screen-sm() {
            width: 100%;
            flex-wrap: wrap;
            flex-direction: column;
            justify-content: flex-start;

            .btn {
                margin: 5px auto;
            }

            .form-group {
                width: 250px !important;
            }
        }
    }

    &__outstanding {
        display: flex;
        align-items: center;

        h3 {
            margin: 0 $spacing 0 0;
            padding: 0;
            font-size: 20px;
            font-weight: $font-bold;
        }

        span {
            font-size: 20px;
        }
    }

    &__date {
        font-size: 20px;
    }
}

.report {
    width: 800px;
    max-width: 100%;
    margin-bottom: $spacing-double;

    &__top-line {
        display: flex;
        align-items: center;
        padding: 5px 0;
        margin-bottom: $spacing-half;
        border-bottom: 2px solid $gray2;
        cursor: pointer;

        a {
            width: 20px;
            font-size: 20px;
            text-decoration: none;
        }

        h4 {
            font-size: 16px;
            padding: 0;
            margin: 0;
        }
    }

    &__form {
        margin-bottom: $spacing-half;
        padding-bottom: $spacing;
        border-bottom: 2px solid $gray2;
    }

    &__values {
        &.--bordered {
            margin-bottom: $spacing-half;
            padding-bottom: $spacing;
            border-bottom: 2px solid $gray2;
        }

        .item-value {
            display: flex;
            padding: 0 5px;
            justify-content: space-between;
            font-size: 14px;
            margin: 5px 0;
        }

        ul {
            list-style: none;
            padding: 0;
        }

        h4 {
            text-align: center;
            margin: 0;
            padding: 0;
            color: #999;
        }

        h5 {
            position: relative;
            left: 50%;
            transform: translateX(-50%);
        }
    }


    .btc-price {
        display: inline-block;
        padding-bottom: 3px;
        margin: 5px auto 0 auto;
        border-bottom: 2px double #444;
    }

    .fa-caret-down {
        display: inline-block;
    }

    .fa-caret-up {
        display: none;
    }
}


.report.is-expanded {
    .fa-caret-down {
        display: none;
    }

    .fa-caret-up {
        display: inline-block;
    }

    .report__top-line {
        border: 0;
    }

    .report__info {
        padding: 10px;
        border: 2px solid $gray2;
    }
}

.payment {
    min-width: 200px;
    width: 800px;
    max-width: 100%;
    margin-bottom: $spacing-double;

    &__top-line {
        display: flex;
        align-items: center;
        padding: 5px 0;
        margin-bottom: $spacing-half;
        border-bottom: 2px solid $gray2;
        cursor: pointer;

        h4, h5 {
            font-size: 16px;
            padding: 0;
            margin: 0;
            color: #999;
        }

        h4 {
            width: 150px;
        }

        h5 {
            width: calc(100% - 170px);
            text-align: right;
        }
    }

    &__info {
        padding: 7px;
    }

    &__item-title {
        color: #999;
    }

    &__qr {
        display: inline-block;
        width: 150px;
        border: 1px solid $gray1;
        padding: $spacing-half;
        border-radius: 5px;
        margin-bottom: $spacing-half;
    }

    &__category {
        display: none;
        margin-bottom: 20px;

        h2 {
            font-size: 16px;
            color: #999;
            margin: 0 0 $spacing-half 0;
            padding: 0 0 $spacing-half 0;
            border-bottom: 2px solid $gray2;
        }

        a {
            font-size: 16px;
            display: inline-block;
            margin-left: $spacing-half;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__items {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
    }

    &__item {
        min-width: 150px;
        display: flex;
        align-items: center;
        margin: 10px 25px;
        font-size: 16px;

        .sprite-container {
            width: 37.5px;
            height: 23px;
            margin-right: $spacing-half;
        }

        .sprite {
            transform: scale(0.5, 0.5) translate(-50%, -50%);
        }

        .fa {
            margin: 0 $spacing-half;
        }

        strong {
            font-weight: $font-bold;
        }
    }

    .row {
        margin-bottom: $spacing-half;
    }

    .flash-value {
        animation-name: anim;
        animation-duration: 1s;
        animation-iteration-count: 1;
        animation-direction: alternate;
        animation-timing-function: linear;
    }

    @keyframes anim {
        50% {
            background-color: #fffed4;
        }
    }

    h3 {
        margin: 0 0 $spacing-half 0;
        padding: 0;
        font-size: 20px;
    }
}
