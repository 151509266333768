.mission {
    padding: $spacing 0;
    text-align: center;

    h2 {
        padding: $spacing-half 0;
        font-weight: $font-bold;
    }

    p {
        margin-top: $spacing;
    }

    img {
        width: 50vw;
        margin-top: $spacing;

        @include screen-sm() {
            width: 80vw;
        }
    }
}
