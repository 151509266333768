.products {
    padding-top: 120px;

    .dashboard {
        &__widget {
            margin: $spacing-double 0 0 0;
        }

        &__content {
            margin: 0;
            width: 100%;

            .btn {
                width: 200px;
                display: block;
                margin: $spacing auto 0 auto;
            }

            h1 {
                margin-bottom: 10px !important;
            }
        }
    }

    @include screen-sm() {
        padding-top: 170px;
    }

    @include screen-xs() {
        padding-top: 150px;
    }
}

.order-review {
    &__title {
        font-size: $font-size-base + 5;
        margin-bottom: $spacing;
    }

    &__content {
        display: flex;
        justify-content: space-between;
        padding: $spacing 0;
        border-bottom: 1px solid $color-divider;
        border-top: 1px solid $color-divider;

        &__column {
            width: 250px;
            max-width: calc(100% / 4);
            margin: 0;
            padding: 0 5px;
            list-style: none;

            &:last-child {
                width: calc(100% - 500px);
                max-width: calc(100% / 2);
            }

            &:last-child .order-review-item {
                justify-content: center;
            }

            &:last-child .order-review-item__key, &:last-child .order-review-item__val {
                display: inline-block;
                margin: 0 $spacing;
            }

            .btn {
                width: 50%;
            }
        }
    }


    @include screen-md() {
        &__content {
            &__column {
                width: 180px;
            }

            &__column:last-child {
                width: calc(100% - 360px);
            }
        }
    }

    @include screen-sm() {
        &__content {
            flex-wrap: wrap;
            padding: $spacing-half;

            &__column {
                width: 100% !important;
                max-width: 100% !important;
                margin-bottom: $spacing;
                border-bottom: 1px solid $color-divider;
            }
        }
    }

    @include screen-xs() {
        &__content {
            &__column:last-child .order-review-item {
                width: 100%;
                flex-wrap: wrap;
            }

            .btn {
                width: 100%;
            }
        }
    }
}

.order-review-item {
    width: 100%;
    margin: 0;
    padding: $spacing-half / 2 0;
    display: flex;
    justify-content: space-between;

    &__key {
        font-weight: $font-bold;
    }

    &__val {
        font-weight: $font-bold;
    }

    &__action {
        width: 100%;
    }
}

.confirm-and-filter {
    width: 100%;
    display: flex;
    align-content: center;
    align-items: center;
    margin: 0 !important;

    &__item {
        width: calc((100% - 200px) / 2);

        & a {
            width: 100%;
        }
    }

    .btn-bnw {
        width: 200px;
        margin: 0 0 10px 0!important;
    }

    @include screen-sm() {
        padding: $spacing-half;
        flex-wrap: wrap;

        &__item {
            width: 200px;
        }
    }

    @include screen-xs() {
        flex-wrap: wrap;

        &__item {
            width: 200px;
        }
    }
}

.checkbox-item {
    & label {
        margin-left: $spacing-half;
        color: $color-font-base;
        font-weight: $font-bold;
    }
}

.flag-list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    &__headers {
        width: 100%;
        display: flex;
        margin-bottom: $spacing;
        border-bottom: 1px solid $color-divider;

        &__item {
            width: calc(100% / 4);
            color: $color-font-light;

            &--last {
                text-align: right;
                padding-right: $spacing;
            }

            &:last-child {
                padding-right: 0;
            }
        }
    }

    &__items {
        display: flex;
        justify-content: flex-start;
        align-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
        width: 100%;
    }

    @include screen-sm() {
        &__headers {
            display: none;
        }
    }
}

.flag-item {
    height: 40px;
    width: calc(100% / 2);
    padding-right: $spacing;
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;

    &__country-info {
        display: flex;
        align-content: center;
        align-items: center;

        &__flag {
            margin-right: $spacing;
            transform: scale(0.55);
        }

        &__name {
            font-weight: $font-bold;
        }
    }

    &__amount {
        & span {
            font-weight: $font-bold;
            margin-right: $spacing-half;
        }

        & input {
            width: 55px;
            text-align: center;
            padding: 0;
        }
    }

    &:nth-child(even) {
        padding-right: 0;
    }

    @include screen-sm() {
        width: calc(100% / 1);
        padding-right: 0;
    }

    @include screen-xs() {
        &__country-info {
            &__flag {
                display: none;
            }
        }

        &__amount {
            span {
                display: none;
            }
        }
    }
}

.confirm-update {
    width: 400px;
    max-width: 100%;
    padding: 20px 10px;

    &__close {
        position: absolute;
        top: 0;
        right: 5px;
        font-size: 20px;
    }

    &__title {
        width: 100%;
        text-align: center;
        font-size: $font-size-base * 2;
        color: $color-font-heading;
    }

    &__list {
        width: 100%;
        margin: $spacing-third 0;
    }

    &__actions {
        display: flex;
        justify-content: center;
    }
}

.confirm-list-item {
    height: 23px;
    width: 100%;
    margin: $spacing-half 0;
    display: flex;
    justify-content: space-between;

    &__country-info {
        display: flex;
        align-content: center;
        align-items: center;

        .sprite-container {
            transform: scale(0.5);
        }

        &__name {
            font-weight: $font-bold;
        }
    }
}

.flags-stock-list {
    width: 100%;

    &__headers {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-bottom: $spacing;
        border-bottom: 1px solid $color-divider;
        color: $color-font-light;
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
    }
}

.flag-stock-item {
    height: 40px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-content: center;
    align-items: center;

    &__country-info {
        width: calc(100% / 3);
        display: flex;
        justify-content: flex-start;
        align-content: center;
        align-items: center;

        &__flag {
            margin-right: $spacing;
            transform: scale(0.55);
        }

        &__name {
            font-weight: $font-bold;
        }
    }

    &__stock {
        width: calc(100% / 3);
        display: flex;
        justify-content: center;
        padding-right: $spacing;
        font-weight: $font-bold;
    }

    &__add {
        width: calc(100% / 3);
        display: flex;
        justify-content: flex-end;
        align-content: center;
        align-items: center;

        & span {
            font-weight: $font-bold;
            margin-right: $spacing-half;
        }

        & input {
            width: 55px;
            text-align: center;
        }
    }

    @include screen-sm() {
        width: calc(100% / 1);
        padding-right: 0;
    }

    @include screen-xs() {
        &__country-info {
            &__flag {
                display: none;
            }
        }

        &__amount {
            span {
                display: none;
            }
        }
    }
}

.stock-level-v-low {
    color: $color-brand-2;
}

.stock-level-low {
    color: $color-brand-3;
}

.stock-level-normal {
    color: #efef00;
}

.stock-level-high {
    color: $color-brand-4;
}

.stock-level-v-high {
    color: $color-brand-5;
}
