.media {
    padding: $spacing 0;
    text-align: center;

    h2 {
        padding: $spacing-half 0;
    }

    .title {
        font-weight: $font-bold;
    }

    p {
        margin-bottom: 0;
        font-size: 20px;
    }

    a {
        cursor: pointer;
        color: #1565c0;
    }
}
