.users {
    padding: $spacing $spacing-half 0 $spacing-half;

    &__content {
        width: 1600px;
        max-width: 100%;
        margin: 0 auto;
        display: flex;
        max-height: 100vh;
        height: 100vh;
    }

    &__details {
        text-align: center;
        width: 100%;

        .users-list {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }

        .btn {
            display: inline-block;
            width: 400px;
            max-width: 100%;
            margin: $spacing-double 0;
        }
    }

    h1 {
        width: 100%;
        text-align: center;
        font-weight: $font-bold;
        font-size: 25px;
        margin-bottom: $spacing;
    }

    h3 {
        width: 100%;
        text-align: center;
        font-weight: $font-bold;
        font-size: 20px;
        font-style: italic;
        color: $color-font-light;
        margin-bottom: $spacing;
    }

    .row {
        width: 60%;
        margin: 0 auto;
        padding-bottom: $spacing;

        .col-centered {
            float: none;
            margin: 0 auto;
        }
    }

    .margin-left {
        margin-left: 50px;
    }

    .margin-right {
        margin-left: 50px;
    }

    .reset-filter {
        text-align: center;
        font-size: 17px;
        margin-bottom: $spacing;

        i {
            margin: 0 7px;
        }
    }

    @include screen-sm() {
        &__content {
            flex-direction: column;
        }

        &__details {
            width: 100%;
        }

        .row {
            width: 90%;
        }
    }
}

.invite-all {
    width: 450px;
    max-width: 100%;

    &__btns {
        text-align: center;
    }

    h2 {
        margin: 0 0 $spacing 0;
        padding: 0;
        font-size: 20px;
        text-align: center;
    }
}
