.events-finance-admin {
    padding: $spacing $spacing-half 0 $spacing-half;

    .supplier {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: $spacing;

        &__left, &__right {
            width: 45%;
        }

        @include screen-xs() {
            flex-direction: column-reverse;
            align-items: center;

            &__left, &__right {
                width: 80%;
            }
        }
    }

    .supplier-table {
        width: 85%;
        margin: 0 auto;

        &__row {
            display: flex;
            justify-content: space-between;

            &:hover {
                background-color: #f7f7f7;
            }
        }

        > .supplier-table__row:first-child {
            border-bottom: 1px solid #999;
            font-size: 16px;
            font-weight: $font-bold;

            &:hover {
                background-color: transparent;
            }
        }

        &__col {
            padding: 7px;
            color: #999;
            width: 33%;
            text-align: center;

            &:first-child {
                text-align: left;
            }

            &:last-child {
                text-align: right;
            }
        }
    }

    .load-more {
        margin-top: $spacing-double;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
    }

    p {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .info-title {
            font-size: 16px;
        }

        .info-value {
            font-size: 16px;
            color: #999;
        }
    }
}
