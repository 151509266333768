.profile-card {
    width: 90%;
    margin: 0 auto;
    padding: $spacing-half 0;

    &__header {
        display: flex;
        align-content: space-between;
        align-items: center;
        margin-bottom: $spacing;
        text-align: left;

        img {
            width: 100px;
            height: 100px;
            object-fit: cover;
            border-radius: 100%;
        }
    }

    &__name {
        margin-left: $spacing;
        width: calc(100% - 100px);

        h2 {
            font-size: 20px;
            font-weight: $font-bold;
            margin: 0;
            padding: 0;
        }

        h5 {
            color: #999;
        }

        span {
            font-style: italic;
        }
    }

    &__details {
        margin-bottom: $spacing;
        text-align: left;
    }

    &__info {
        display: flex;
        margin-bottom: $spacing-half;

        .info-value {
            width: 62%;
            display: flex;
            flex-wrap: wrap;
        }

        .bold {
            font-weight: $font-bold;
        }

        .sprite-container {
            width: 37.5px;
            height: 23px;
            margin-bottom: 5px;
        }

        .sprite {
            transform: scale(0.5, 0.5) translate(-50%, -50%);
        }

        h5 {
            width: 36%;
            color: #999;
            font-size: 16px;
            margin: 0;
            padding: 0;
        }
    }

    &__button {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
    }


    @include screen-sm() {
        text-align: center;

        &__header {
            justify-content: center;
        }

        &__name {
            width: auto;
        }

        .btn {
            float: none;
        }
    }
}
