.sprite-container {
    width: 75px;
    height: 46px;
    margin-right: 5px;
}

.sprite {
    background-image: url('../images/sprites/flag-sprite2.png');
    background-repeat: no-repeat;
    display: inline-block;
    position: relative;
    margin: 0;
    padding: 0;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.75);
}

.sprite-ML-Flag-Sprite-13 {
    width: 75px;
    height: 46px;
    background-position: -5px -5px;
}

.sprite-afghanistan {
    width: 75px;
    height: 46px;
    background-position: -90px -5px;
}

.sprite-albania {
    width: 75px;
    height: 46px;
    background-position: -175px -5px;
}

.sprite-algeria {
    width: 75px;
    height: 46px;
    background-position: -260px -5px;
}

.sprite-ambassador {
    width: 75px;
    height: 46px;
    background-position: -345px -5px;
}

.sprite-andorra {
    width: 75px;
    height: 46px;
    background-position: -430px -5px;
}

.sprite-angola {
    width: 75px;
    height: 46px;
    background-position: -515px -5px;
}

.sprite-antigua_barbuda {
    width: 75px;
    height: 46px;
    background-position: -600px -5px;
}

.sprite-aramaic {
    width: 75px;
    height: 46px;
    background-position: -685px -5px;
}

.sprite-argentina {
    width: 75px;
    height: 46px;
    background-position: -770px -5px;
}

.sprite-armenia {
    width: 75px;
    height: 46px;
    background-position: -855px -5px;
}

.sprite-associate {
    width: 75px;
    height: 46px;
    background-position: -940px -5px;
}

.sprite-australia {
    width: 75px;
    height: 46px;
    background-position: -5px -61px;
}

.sprite-austria {
    width: 75px;
    height: 46px;
    background-position: -90px -61px;
}

.sprite-azerbaijan {
    width: 75px;
    height: 46px;
    background-position: -175px -61px;
}

.sprite-bahamas {
    width: 75px;
    height: 46px;
    background-position: -260px -61px;
}

.sprite-bahrain {
    width: 75px;
    height: 46px;
    background-position: -345px -61px;
}

.sprite-bangladesh {
    width: 75px;
    height: 46px;
    background-position: -430px -61px;
}

.sprite-barbados {
    width: 75px;
    height: 46px;
    background-position: -515px -61px;
}

.sprite-basque {
    width: 75px;
    height: 46px;
    background-position: -600px -61px;
}

.sprite-belarus {
    width: 75px;
    height: 46px;
    background-position: -685px -61px;
}

.sprite-belgium {
    width: 75px;
    height: 46px;
    background-position: -770px -61px;
}

.sprite-belize {
    width: 75px;
    height: 46px;
    background-position: -855px -61px;
}

.sprite-berber {
    width: 75px;
    height: 46px;
    background-position: -940px -61px;
}

.sprite-benin {
    width: 75px;
    height: 46px;
    background-position: -5px -117px;
}

.sprite-bhutan {
    width: 75px;
    height: 46px;
    background-position: -90px -117px;
}

.sprite-birthday {
    width: 75px;
    height: 46px;
    background-position: -175px -117px;
}

.sprite-bolivia {
    width: 75px;
    height: 46px;
    background-position: -260px -117px;
}

.sprite-bosnia {
    width: 75px;
    height: 46px;
    background-position: -345px -117px;
}

.sprite-botswana {
    width: 75px;
    height: 46px;
    background-position: -430px -117px;
}

.sprite-brazil {
    width: 75px;
    height: 46px;
    background-position: -515px -117px;
}

.sprite-brunei {
    width: 75px;
    height: 47px;
    background-position: -600px -117px;
}

.sprite-bulgaria {
    width: 75px;
    height: 46px;
    background-position: -685px -117px;
}

.sprite-burkina_faso {
    width: 75px;
    height: 46px;
    background-position: -770px -117px;
}

.sprite-burundi {
    width: 75px;
    height: 46px;
    background-position: -855px -117px;
}

.sprite-cambodia {
    width: 75px;
    height: 46px;
    background-position: -940px -117px;
}

.sprite-cameroon {
    width: 75px;
    height: 46px;
    background-position: -5px -173px;
}

.sprite-canada {
    width: 75px;
    height: 46px;
    background-position: -90px -173px;
}

.sprite-canary {
    width: 75px;
    height: 46px;
    background-position: -175px -173px;
}

.sprite-cape_verde {
    width: 75px;
    height: 46px;
    background-position: -260px -173px;
}

.sprite-catalan {
    width: 75px;
    height: 46px;
    background-position: -345px -173px;
}

.sprite-central_africa_republic {
    width: 75px;
    height: 46px;
    background-position: -430px -173px;
}

.sprite-chad {
    width: 75px;
    height: 46px;
    background-position: -515px -173px;
}

.sprite-chile {
    width: 75px;
    height: 46px;
    background-position: -685px -173px;
}

.sprite-china {
    width: 75px;
    height: 46px;
    background-position: -770px -173px;
}

.sprite-cologne {
    width: 75px;
    height: 46px;
    background-position: -855px -173px;
}

.sprite-colombia {
    width: 75px;
    height: 46px;
    background-position: -940px -173px;
}

.sprite-comoros {
    width: 75px;
    height: 46px;
    background-position: -5px -229px;
}

.sprite-congo {
    width: 75px;
    height: 46px;
    background-position: -90px -229px;
}

.sprite-cook_island {
    width: 75px;
    height: 46px;
    background-position: -175px -229px;
}

.sprite-costa_rica {
    width: 75px;
    height: 46px;
    background-position: -260px -229px;
}

.sprite-cote_divoire {
    width: 75px;
    height: 46px;
    background-position: -345px -229px;
}

.sprite-crimea {
    width: 75px;
    height: 46px;
    background-position: -430px -229px;
}

.sprite-croatia {
    width: 75px;
    height: 46px;
    background-position: -515px -229px;
}

.sprite-cuba {
    width: 75px;
    height: 46px;
    background-position: -600px -229px;
}

.sprite-curacao {
    width: 75px;
    height: 46px;
    background-position: -685px -229px;
}

.sprite-cyprus {
    width: 75px;
    height: 46px;
    background-position: -770px -229px;
}

.sprite-czech {
    width: 75px;
    height: 46px;
    background-position: -855px -229px;
}

.sprite-denmark {
    width: 75px;
    height: 46px;
    background-position: -940px -229px;
}

.sprite-djibouti {
    width: 75px;
    height: 46px;
    background-position: -5px -285px;
}

.sprite-dominica {
    width: 75px;
    height: 46px;
    background-position: -90px -285px;
}

.sprite-dominican_republic {
    width: 75px;
    height: 46px;
    background-position: -175px -285px;
}

.sprite-east_timor {
    width: 75px;
    height: 46px;
    background-position: -260px -285px;
}

.sprite-ecuador {
    width: 75px;
    height: 46px;
    background-position: -345px -285px;
}

.sprite-egypt {
    width: 75px;
    height: 46px;
    background-position: -430px -285px;
}

.sprite-el_salvador {
    width: 75px;
    height: 46px;
    background-position: -515px -285px;
}

.sprite-england {
    width: 75px;
    height: 46px;
    background-position: -600px -285px;
}

.sprite-equatorial_guinea {
    width: 75px;
    height: 46px;
    background-position: -685px -285px;
}

.sprite-eritrea {
    width: 75px;
    height: 46px;
    background-position: -770px -285px;
}

.sprite-esperanto {
    width: 75px;
    height: 46px;
    background-position: -855px -285px;
}

.sprite-estonia {
    width: 75px;
    height: 46px;
    background-position: -940px -285px;
}

.sprite-ethiopia {
    width: 75px;
    height: 46px;
    background-position: -5px -341px;
}

.sprite-faroe_island {
    width: 75px;
    height: 46px;
    background-position: -90px -341px;
}

.sprite-fiji {
    width: 75px;
    height: 46px;
    background-position: -175px -341px;
}

.sprite-finland {
    width: 75px;
    height: 46px;
    background-position: -260px -341px;
}

.sprite-flemish {
    width: 75px;
    height: 46px;
    background-position: -345px -341px;
}

.sprite-france {
    width: 75px;
    height: 46px;
    background-position: -430px -341px;
}

.sprite-french_polynesia {
    width: 75px;
    height: 46px;
    background-position: -515px -341px;
}

.sprite-gabon {
    width: 75px;
    height: 46px;
    background-position: -600px -341px;
}

.sprite-galicia {
    width: 75px;
    height: 46px;
    background-position: -685px -341px;
}

.sprite-gambia {
    width: 75px;
    height: 46px;
    background-position: -770px -341px;
}

.sprite-georgia {
    width: 75px;
    height: 46px;
    background-position: -855px -341px;
}

.sprite-germany {
    width: 75px;
    height: 46px;
    background-position: -940px -341px;
}

.sprite-ghana {
    width: 75px;
    height: 46px;
    background-position: -5px -397px;
}

.sprite-greece {
    width: 75px;
    height: 46px;
    background-position: -90px -397px;
}

.sprite-greenland {
    width: 75px;
    height: 46px;
    background-position: -175px -397px;
}

.sprite-grenada {
    width: 75px;
    height: 46px;
    background-position: -260px -397px;
}

.sprite-guadaloupe {
    width: 75px;
    height: 46px;
    background-position: -345px -397px;
}

.sprite-guatemala {
    width: 75px;
    height: 46px;
    background-position: -430px -397px;
}

.sprite-guinea {
    width: 75px;
    height: 46px;
    background-position: -515px -397px;
}

.sprite-guinea_bissau {
    width: 75px;
    height: 46px;
    background-position: -600px -397px;
}

.sprite-guyana {
    width: 75px;
    height: 46px;
    background-position: -685px -397px;
}

.sprite-haiti {
    width: 75px;
    height: 46px;
    background-position: -770px -397px;
}

.sprite-hawaii {
    width: 75px;
    height: 46px;
    background-image: url('https://mundolingo.org/images/sprites/hawaii.png')!important;
    background-repeat: no-repeat;
    background-size: 75px 46px!important;
    display: inline-block;
    position: relative;
    margin: 0;
    padding: 0;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.75);
}

.sprite-honduras {
    width: 75px;
    height: 46px;
    background-position: -940px -397px;
}

.sprite-honk_kong {
    width: 75px;
    height: 46px;
    background-position: -5px -453px;
}

.sprite-hungary {
    width: 75px;
    height: 46px;
    background-position: -90px -453px;
}

.sprite-iceland {
    width: 75px;
    height: 46px;
    background-position: -175px -453px;
}

.sprite-india {
    width: 75px;
    height: 46px;
    background-position: -260px -453px;
}

.sprite-indonesia {
    width: 75px;
    height: 46px;
    background-position: -345px -453px;
}

.sprite-iran {
    width: 75px;
    height: 46px;
    background-position: -430px -453px;
}

.sprite-iraq {
    width: 75px;
    height: 46px;
    background-position: -515px -453px;
}

.sprite-ireland {
    width: 75px;
    height: 46px;
    background-position: -600px -453px;
}

.sprite-israel {
    width: 75px;
    height: 46px;
    background-position: -685px -453px;
}

.sprite-italy {
    width: 75px;
    height: 46px;
    background-position: -770px -453px;
}

.sprite-jamaica {
    width: 75px;
    height: 46px;
    background-position: -855px -453px;
}

.sprite-japan {
    width: 75px;
    height: 46px;
    background-position: -940px -453px;
}

.sprite-jordan {
    width: 75px;
    height: 46px;
    background-position: -5px -509px;
}

.sprite-kanak {
    width: 75px;
    height: 46px;
    background-position: -90px -509px;
}

.sprite-kazakhstan {
    width: 75px;
    height: 46px;
    background-position: -175px -509px;
}

.sprite-kenya {
    width: 75px;
    height: 46px;
    background-position: -260px -509px;
}

.sprite-kiribati {
    width: 75px;
    height: 46px;
    background-position: -345px -509px;
}

.sprite-kosovo {
    width: 75px;
    height: 46px;
    background-position: -430px -509px;
}

.sprite-kurdistan {
    width: 75px;
    height: 46px;
    background-position: -515px -509px;
}

.sprite-kuwait {
    width: 75px;
    height: 46px;
    background-position: -600px -509px;
}

.sprite-kyrgyzstan {
    width: 75px;
    height: 46px;
    background-position: -685px -509px;
}

.sprite-laos {
    width: 75px;
    height: 46px;
    background-position: -770px -509px;
}

.sprite-latvia {
    width: 75px;
    height: 46px;
    background-position: -855px -509px;
}

.sprite-lebanon {
    width: 75px;
    height: 46px;
    background-position: -940px -509px;
}

.sprite-lesotho {
    width: 75px;
    height: 46px;
    background-position: -5px -565px;
}

.sprite-liberia {
    width: 75px;
    height: 46px;
    background-position: -90px -565px;
}

.sprite-libya {
    width: 75px;
    height: 46px;
    background-position: -175px -565px;
}

.sprite-liechtenstein {
    width: 75px;
    height: 46px;
    background-position: -260px -565px;
}

.sprite-lithuania {
    width: 75px;
    height: 46px;
    background-position: -345px -565px;
}

.sprite-luxembourg {
    width: 75px;
    height: 46px;
    background-position: -430px -565px;
}

.sprite-macao {
    width: 75px;
    height: 46px;
    background-position: -515px -565px;
}

.sprite-macedonia {
    width: 75px;
    height: 46px;
    background-position: -600px -565px;
}

.sprite-madagascar {
    width: 75px;
    height: 46px;
    background-position: -685px -565px;
}

.sprite-malawi {
    width: 75px;
    height: 46px;
    background-position: -770px -565px;
}

.sprite-malaysia {
    width: 75px;
    height: 46px;
    background-position: -855px -565px;
}

.sprite-maldives {
    width: 75px;
    height: 46px;
    background-position: -940px -565px;
}

.sprite-mali {
    width: 75px;
    height: 46px;
    background-position: -5px -621px;
}

.sprite-malta {
    width: 75px;
    height: 46px;
    background-position: -90px -621px;
}

.sprite-maori {
    width: 75px;
    height: 46px;
    background-position: -179px -623px;
}

.sprite-marshal_islands {
    width: 75px;
    height: 45px;
    background-position: -260px -621px;
}

.sprite-martinique {
    width: 75px;
    height: 46px;
    background-position: -345px -621px;
}

.sprite-mauritania {
    width: 75px;
    height: 45px;
    background-position: -430px -621px;
}

.sprite-mauritius {
    width: 75px;
    height: 46px;
    background-position: -515px -621px;
}

.sprite-mexico {
    width: 75px;
    height: 46px;
    background-position: -600px -621px;
}

.sprite-micronesia_federated {
    width: 75px;
    height: 46px;
    background-position: -685px -621px;
}

.sprite-moldova {
    width: 75px;
    height: 46px;
    background-position: -770px -621px;
}

.sprite-monaco {
    width: 75px;
    height: 46px;
    background-position: -855px -621px;
}

.sprite-mongolia {
    width: 75px;
    height: 46px;
    background-position: -940px -621px;
}

.sprite-montenegro {
    width: 75px;
    height: 46px;
    background-position: -5px -677px;
}

.sprite-morocco {
    width: 75px;
    height: 46px;
    background-position: -90px -677px;
}

.sprite-mozambique {
    width: 75px;
    height: 46px;
    background-position: -175px -677px;
}

.sprite-myanmar {
    width: 75px;
    height: 46px;
    background-position: -260px -677px;
}

.sprite-namibia {
    width: 75px;
    height: 46px;
    background-position: -345px -677px;
}

.sprite-nauru {
    width: 75px;
    height: 46px;
    background-position: -430px -677px;
}

.sprite-nepal {
    width: 75px;
    height: 46px;
    background-position: -515px -677px;
}

.sprite-netherlands {
    width: 75px;
    height: 46px;
    background-position: -600px -677px;
}

.sprite-new-zealand {
    width: 75px;
    height: 46px;
    background-position: -685px -677px;
}

.sprite-nicaragua {
    width: 75px;
    height: 46px;
    background-position: -770px -677px;
}

.sprite-niger {
    width: 75px;
    height: 46px;
    background-position: -855px -677px;
}

.sprite-nigeria {
    width: 75px;
    height: 46px;
    background-position: -940px -677px;
}

.sprite-north_korea {
    width: 75px;
    height: 46px;
    background-position: -5px -733px;
}

.sprite-norway {
    width: 75px;
    height: 46px;
    background-position: -90px -733px;
}

.sprite-oman {
    width: 75px;
    height: 46px;
    background-position: -175px -733px;
}

.sprite-pakistan {
    width: 75px;
    height: 46px;
    background-position: -260px -733px;
}

.sprite-palau {
    width: 75px;
    height: 46px;
    background-position: -345px -733px;
}

.sprite-palestine {
    width: 75px;
    height: 46px;
    background-position: -430px -733px;
}

.sprite-panama {
    width: 75px;
    height: 46px;
    background-position: -515px -733px;
}

.sprite-papua_new_guinea {
    width: 75px;
    height: 46px;
    background-position: -600px -733px;
}

.sprite-paraguay {
    width: 75px;
    height: 46px;
    background-position: -685px -733px;
}

.sprite-peru {
    width: 75px;
    height: 46px;
    background-position: -770px -733px;
}

.sprite-philippines {
    width: 75px;
    height: 46px;
    background-position: -855px -733px;
}

.sprite-poland {
    width: 75px;
    height: 46px;
    background-position: -940px -733px;
}

.sprite-portugal {
    width: 75px;
    height: 46px;
    background-position: -5px -789px;
}

.sprite-puerto_rico {
    width: 75px;
    height: 46px;
    background-position: -90px -789px;
}

.sprite-qatar {
    width: 75px;
    height: 46px;
    background-position: -175px -789px;
}

.sprite-quebec {
    width: 75px;
    height: 46px;
    background-position: -260px -789px;
}

.sprite-romania {
    width: 75px;
    height: 46px;
    background-position: -345px -789px;
}

.sprite-russia {
    width: 75px;
    height: 46px;
    background-position: -430px -789px;
}

.sprite-rwanda {
    width: 75px;
    height: 46px;
    background-position: -515px -789px;
}

.sprite-saint_kitts_nevis {
    width: 75px;
    height: 46px;
    background-position: -600px -789px;
}

.sprite-saint_lucia {
    width: 75px;
    height: 46px;
    background-position: -685px -789px;
}

.sprite-samoa {
    width: 75px;
    height: 46px;
    background-position: -770px -789px;
}

.sprite-san_marino {
    width: 75px;
    height: 46px;
    background-position: -855px -789px;
}

.sprite-sao_tome_and_principe {
    width: 75px;
    height: 46px;
    background-position: -940px -789px;
}

.sprite-saudi_arabia {
    width: 75px;
    height: 46px;
    background-position: -5px -845px;
}

.sprite-scotland {
    width: 75px;
    height: 46px;
    background-position: -90px -845px;
}

.sprite-senegal {
    width: 75px;
    height: 46px;
    background-position: -175px -845px;
}

.sprite-serbia {
    width: 75px;
    height: 46px;
    background-position: -260px -845px;
}

.sprite-seychelles {
    width: 75px;
    height: 46px;
    background-position: -345px -845px;
}

.sprite-sierra_leone {
    width: 75px;
    height: 46px;
    background-position: -430px -845px;
}

.sprite-sign_language {
    width: 75px;
    height: 46px;
    background-position: -515px -845px;
}

.sprite-singapore {
    width: 75px;
    height: 46px;
    background-position: -600px -845px;
}

.sprite-slovakia {
    width: 75px;
    height: 46px;
    background-position: -685px -845px;
}

.sprite-slovenia {
    width: 75px;
    height: 46px;
    background-position: -770px -845px;
}

.sprite-solomon_islands {
    width: 75px;
    height: 46px;
    background-position: -855px -845px;
}

.sprite-somalia {
    width: 75px;
    height: 46px;
    background-position: -940px -845px;
}

.sprite-south_africa {
    width: 75px;
    height: 46px;
    background-position: -5px -901px;
}

.sprite-south_korea {
    width: 75px;
    height: 46px;
    background-position: -90px -901px;
}

.sprite-south_sudan {
    width: 75px;
    height: 46px;
    background-position: -181px -906px;
}

.sprite-st_vincent_grenadines {
    width: 75px;
    height: 46px;
    background-image: url('https://mundolingo.org/images/sprites/st_vincent_grenadines.png')!important;
    background-repeat: no-repeat;
    background-size: 75px 46px!important;
    display: inline-block;
    position: relative;
    margin: 0;
    padding: 0;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.75);
}

.sprite-spain {
    width: 75px;
    height: 46px;
    background-position: -260px -901px;
}

.sprite-sri_lanka {
    width: 75px;
    height: 46px;
    background-position: -345px -901px;
}

.sprite-sudan {
    width: 75px;
    height: 46px;
    background-position: -430px -901px;
}

.sprite-suriname {
    width: 75px;
    height: 46px;
    background-position: -515px -901px;
}

.sprite-swaziland {
    width: 75px;
    height: 46px;
    background-position: -600px -901px;
}

.sprite-sweden {
    width: 75px;
    height: 46px;
    background-position: -685px -901px;
}

.sprite-switzerland {
    width: 75px;
    height: 46px;
    background-position: -770px -901px;
}

.sprite-syria {
    width: 75px;
    height: 46px;
    background-position: -855px -901px;
}

.sprite-taiwan {
    width: 75px;
    height: 46px;
    background-position: -940px -901px;
}

.sprite-tajikistan {
    width: 75px;
    height: 46px;
    background-position: -5px -957px;
}

.sprite-tanzania {
    width: 75px;
    height: 46px;
    background-position: -90px -957px;
}

.sprite-thailand {
    width: 75px;
    height: 46px;
    background-position: -175px -957px;
}

.sprite-togo {
    width: 75px;
    height: 46px;
    background-position: -260px -957px;
}

.sprite-tonga {
    width: 75px;
    height: 46px;
    background-position: -345px -957px;
}

.sprite-trinidad_tobago {
    width: 75px;
    height: 46px;
    background-position: -430px -957px;
}

.sprite-tunisia {
    width: 75px;
    height: 46px;
    background-position: -515px -957px;
}

.sprite-turkey {
    width: 75px;
    height: 46px;
    background-position: -600px -957px;
}

.sprite-turkmenistan {
    width: 75px;
    height: 46px;
    background-position: -685px -957px;
}

.sprite-tuvalu {
    width: 75px;
    height: 46px;
    background-position: -770px -957px;
}

.sprite-uganda {
    width: 75px;
    height: 47px;
    background-position: -855px -957px;
}

.sprite-ukraine {
    width: 75px;
    height: 46px;
    background-position: -940px -957px;
}

.sprite-united_arab_emirates {
    width: 75px;
    height: 46px;
    background-position: -1025px -5px;
}

.sprite-united_kingdom {
    width: 75px;
    height: 46px;
    background-position: -1025px -61px;
}

.sprite-united_states_of_america {
    width: 75px;
    height: 46px;
    background-position: -1025px -117px;
}

.sprite-uruguay {
    width: 75px;
    height: 46px;
    background-position: -1025px -173px;
}

.sprite-uzbekistan {
    width: 75px;
    height: 46px;
    background-position: -1025px -229px;
}

.sprite-vanuatu {
    width: 75px;
    height: 46px;
    background-position: -1025px -285px;
}

.sprite-vatican_city {
    width: 75px;
    height: 46px;
    background-position: -1025px -341px;
}

.sprite-venezuela {
    width: 75px;
    height: 46px;
    background-position: -1025px -397px;
}

.sprite-vietnam {
    width: 75px;
    height: 46px;
    background-position: -1025px -453px;
}

.sprite-wales {
    width: 75px;
    height: 46px;
    background-position: -1025px -509px;
}

.sprite-western_sahara {
    width: 75px;
    height: 46px;
    background-position: -1025px -565px;
}

.sprite-yemen {
    width: 75px;
    height: 46px;
    background-position: -1025px -621px;
}

.sprite-yiddish {
    width: 75px;
    height: 46px;
    background-position: -1025px -677px;
}

.sprite-zambia {
    width: 75px;
    height: 46px;
    background-position: -1025px -733px;
}

.sprite-zimbabwe {
    width: 75px;
    height: 46px;
    background-position: -1025px -789px;
}
