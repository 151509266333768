.events-finance-supplier {
    padding: $spacing $spacing-half 0 $spacing-half;

    .supplier {
        width: 85%;
        margin: 0 auto $spacing auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        &__left {
            min-width: 45%;
        }

        @include screen-xs() {
            flex-direction: column-reverse;
            align-items: center;
        }
    }

    .supplier-table {
        width: 85%;
        margin: 0 auto;

        &__row {
            display: flex;
            justify-content: space-between;

            &:hover {
                background-color: #f7f7f7;
            }
        }

        &__row.--header {
            border-bottom: 1px solid #999;
            font-size: 16px;
            font-weight: $font-bold;

            &:hover {
                background-color: transparent;
            }
        }

        &__col {
            width: 33%;
            padding: 7px;
            color: #999;
            text-align: center;

            &:first-child {
                text-align: left;
            }

            &:last-child {
                text-align: right;
            }
        }
    }

    .load-more {
        display: block;
        width: 200px;
        margin: $spacing-double auto 0 auto;
    }

    p {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .info-title {
            font-size: 16px;
        }

        .info-value {
            font-size: 16px;
            color: #999;
        }
    }
}

.new-transaction {
    width: 400px;
    max-width: 100%;

    &__buttons {
        display: flex;
        align-items: center;
        justify-content: center;

        .btn:first-child {
            margin-right: $spacing-half;
        }
    }

    .new-transaction-pane {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        margin: 0;
        width: 100%;
        opacity: 0;
        contain: content;
        transform: translate3d(100%, 0, 0);
        transition: opacity 300ms ease-out, transform 300ms ease-out;

        &.is-active {
            position: relative;
            opacity: 1;
            transform: translate3d(0, 0, 0);
        }

        &.is-exiting {
            opacity: 0;
            transform: translate3d(-100%, 0, 0);
        }
    }

    h2 {
        margin: 0 0 $spacing 0;
        padding: 0;
        font-size: 22px;
        text-align: center;
        font-weight: $font-bold;
    }

    h3 {
        margin: 0 0 $spacing 0;
        padding: 0 0 $spacing 0;
        font-size: 18px;
        text-align: center;
        border-bottom: 2px solid $gray2;
    }

    h4 {
        margin: 0 0 $spacing 0;
        padding: 0;
        font-size: 18px;
        text-align: center;
        font-weight: $font-bold;
    }

    @include screen-xs() {
        padding: 30px 5px 5px 5px;
    }
}
