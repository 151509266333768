.city {
    padding: $spacing 0;

    &__facebook-banner {
        width: 100%;
        margin: $spacing-third 0;
        background-color: $gray1;
        text-align: center;
        font-size: 20px;
        padding: 15px $spacing-half;
        display: flex;
        align-items: center;
        justify-content: center;

        a {
            display: inline-block;
            font-size: 40px;
            margin-left: $spacing;

            &:last-child {
                margin-left: 5px;
            }
        }
    }

    &__facebook-comments {
        margin-top: $spacing-third;
    }

    &__show-all {
        position: relative;
        left: 50%;
        transform: translateX(-50%);
    }

    .events, .team {
        &__header {
            position: relative;

            .btn {
                position: absolute;
                right: 0;
                top: 0;
                padding: 0 $spacing-half;
                font-size: 18px;
                line-height: 45px;
                height: 45px;
                margin: 0;
            }

            h1 {
                text-align: center;
                font-size: 25px;
                font-weight: $font-bold;
                margin: 0;
                line-height: 45px;
            }
        }

        &__list {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: flex-start;
        }

        @include screen-sm() {
            .btn {
                position: relative;
                display: block;
                width: 200px;
                margin: 0 auto;
            }
        }

        @include screen-xs() {
            margin: 0;
            padding: 0;
        }
    }
}

.new-event {
    width: 400px;
    max-width: 100%;
    border-radius: 0;
    border: 1px solid #636363;
    padding: $spacing-half;
    text-align: center;

    &__close {
        position: absolute;
        top: 0;
        right: 5px;
        font-size: 20px;
    }

    &__content {
        margin: $spacing 0;
    }

    .radio {
        display: flex;
        flex-wrap: wrap;

        label {
            width: 70px;
        }
    }

    h4 {
        color: #999;
        margin: 0;
        padding: 0 0 0 $spacing-half;
        font-size: 16px;
        text-align: left;
    }

    h2 {
        font-size: 20px;
        text-align: center;
        font-weight: $font-bold;
        margin-bottom: $spacing;
    }
}
