.profile {
    padding: $spacing-double 0 $spacing 0;

    &__separator {
        background-color: $gray1;
        text-align: center;
        font-size: 20px;
        padding: 5px;
        font-weight: $font-bold;

        .edit-profile-button {
            display: inline-block;
            opacity: 0;
            position: absolute;
            right: 10px;
            transform: translateX(-100%);
            transition: opacity 0.5s;
        }
    }

    .profile-info {
        position: relative;
        margin-bottom: $spacing;

        &__name {
            margin: 0 0 $spacing-half 0;
            padding: 0;
            font-size: 25px;
            font-weight: $font-bold;
            text-align: center;
        }

        &__date {
            margin: 0 0 $spacing-half 0;
            padding: 0;
            font-size: 18px;
            font-style: italic;
            color: #999;
            text-align: center;
        }

        &__details {
            width: 100%;
        }

        &__avatar {
            position: absolute;
            top: 0;
            left: 150px;
            border-radius: 100%;
            overflow: hidden;

            img {
                border-radius: 100%;
                border: 5px solid $gray2;
                width: 150px;
                height: 150px;
                object-fit: cover;
            }
        }

        &__avatar:hover {
            .profile-info__avatar-overlay {
                opacity: 1;
            }
        }

        &__avatar-overlay {
            opacity: 0;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.3);
            transition: opacity 0.5s;

            a {
                display: inline-block;
                font-size: 25px;
                position: absolute;
                top: calc(100% - 10px);
                left: 50%;
                color: $gray2;
                transform: translateX(-50%) translateY(-100%);
            }

            a:hover {
                color: #fff;
            }

            input {
                width: 0 !important;
                position: absolute;
                left: -1000px;
            }
        }

        .info-item {
            margin: 0 0 $spacing-half 0;
            padding: 0;
            font-size: 16px;
            display: flex;
            justify-content: space-between;

            .info-value {
                width: 50%;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
            }

            h4 {
                width: 45%;
                text-align: right;
                color: #999;
            }
        }

        .sprite-container {
            width: 37.5px;
            height: 23px;
        }

        .sprite {
            transform: scale(0.5, 0.5) translate(-50%, -50%);
        }

        &:hover {
            .edit-profile-button {
                opacity: 1;
            }
        }

        @include screen-md() {
            display: flex;
            flex-direction: column-reverse;
            align-items: center;

            &__avatar {
                position: relative !important;
                left: 0;
                margin-bottom: $spacing;
            }
        }
    }

    .profile-events {
        &__list {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }
    }
}


.edit-user {
    width: 400px;
    max-width: 100%;
    border-radius: 0;
    border: 1px solid #636363;
    padding: $spacing-half;
    text-align: center;

    &__close {
        position: absolute;
        top: 0;
        right: 5px;
        font-size: 20px;
    }

    &__content {
        margin: $spacing 0;
    }

    &__add {
        display: block;
        text-align: left;
        margin-bottom: $spacing;
        color: #999;

        .fa {
            display: inline-block;
            margin-right: $spacing-half;
        }
    }

    .rich-autocomplete-list {
        max-height: 100px !important;

        .sprite-container {
            width: 37.5px;
            height: 23px;
            margin-right: $spacing-half;
        }

        .sprite {
            transform: scale(0.5, 0.5) translate(-50%, -50%);
        }

        .flex {
            display: flex;
            align-items: center;
        }
    }

    h4 {
        color: #999;
        margin: 0;
        padding: 0 0 0 $spacing-half;
        font-size: 16px;
        text-align: left;
    }

    h2 {
        font-size: 20px;
        text-align: center;
        font-weight: $font-bold;
        margin-bottom: $spacing;
    }
}

