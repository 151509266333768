.landing {
    height: calc(100% - 114px);
    display: flex;
    overflow: hidden;
    flex-direction: column;
    justify-content: space-between;

    &__text {
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: calc(100% - 15vw);

        h1 {
            font-size: 30px;
            font-weight: $font-bold;
        }

        img, picture {
            margin: $spacing-double auto 0 auto;
            width: 300px;
            max-width: 100%;
        }
    }

    &__images {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;


        img, picture {
            width: calc(100% / 5);
            height: 20vw;
            margin: 0;
            padding: 0;
        }
    }

    .form-group {
        width: 200px;
        margin: $spacing auto 0 auto;
    }

    @include screen-lg() {
        &__text {
            height: calc(100% - 25vw);

            h1 {
                font-size: 27px;
            }

            h3 {
                font-size: 22px;
            }

            img {
                margin-top: 20px;
            }
        }

        &__images {
            img, picture {
                width: calc(100% / 4);
                height: 25vw;
            }
        }
    }

    @include screen-md() {
        &__text {
            height: calc(100% - 33.3vw);
        }

        &__images {
            img, picture {
                width: calc(100% / 3);
                height: 33.3vw;
            }
        }
    }

    @include screen-sm() {
        height: calc(100% - 167px);

        &__text {
            height: calc(100% - 50vw);

            h1 {
                font-size: 25px;
            }

            h3 {
                font-size: 20px;
            }

            img {
                margin-top: 15px;
            }
        }

        &__images {
            img, picture {
                width: calc(100% / 2);
                height: 50vw;
            }
        }
    }

    @include screen-xs() {
        height: auto;

        &__text {
            h1 {
                font-size: 20px;
            }

            h3 {
                font-size: 16px;
            }
        }

        &__images {
            margin-top: $spacing-third;
        }
    }
}
