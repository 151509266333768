/** General section styles **/

.interview {
    padding-top: $spacing;
    padding-bottom: $spacing-third;

    @include screen-sm {
        padding-top: 0;
    }

    &__heading {
        text-align: center;
        margin-top: 25px;
        margin-bottom: $spacing-double;

        @include screen-sm {
            font-size: 24px;
        }
    }

    &__image {
        width: 8rem;
        margin: 0 auto;
        margin-bottom: $spacing;
    }

    &__title {
        text-align: center;
        margin-bottom: $spacing;
    }

    &__subtitle {
        text-align: center;
        margin-top: $spacing;
    }

    &__lead {
        text-align: center;
        margin-bottom: $spacing-double;
    }

    &__btn-wrap {
        margin-top: $spacing-third;
        float: right;
    }

    &__paragraph {
        text-align: center;
    }

    &__inputs {
        margin-bottom: $spacing-double;
        margin-top: $spacing-double;
    }

    &__upload {
        background-color: transparent !important;
        color: #fff !important;
        background-size: 100% 200% !important;
        background-image: linear-gradient(to bottom, #6fa8dc 50%, #5a9cd7 50%) !important;
        border-radius: 0;
        background-clip: padding-box !important;
        border: 0 !important;
    }

    &__social {
        text-align: center;
    }

    &__banner {
        text-align: center;
        margin-bottom: $spacing;
        height: 15rem;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .done-section {
        @include screen-sm {
            top: 430px;
        }
    }

    .twitter {
        color: #00aced;
    }

    .facebook {
        color: #3b58a4;
    }

    .google {
        color: #dd492b;
    }

    .linkedin {
        color: #0079bd;
    }

    .upload-button {
        margin-top: 0;
    }
}

.interview-content {
    position: relative;
    overflow: hidden;
}

.interview-section {
    padding: $spacing-double 0;
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 70%;
    opacity: 0;
    transform: translate3d(100%, 0, 0);

    @include screen-sm {
        width: 100%;
    }

    &.is-active {
        position: relative;
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }

    &.is-exiting {
        opacity: 0;
        transform: translate3d(-100%, 0, 0);
    }

    &.is-animating {
        transition: opacity 300ms ease-out, transform 300ms ease-out;
    }
}
