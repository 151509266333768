.recover-pass {
    min-width: 290px;
    max-width: 450px;
    padding: 40px;
    margin: auto;
    text-align: left;
    color: $color-font-base;
    box-shadow: 0 5px 25px rgba(0, 0, 0, 0.2);
    border-radius: $border-radius-base;
    position: relative;
    overflow-x: hidden;
    overflow-y: hidden;
    height: auto;
    background-color: #fff;

    &__logo {
        display: block;
        margin: 0 auto;
        width: 100px;
    }

    &__title {
        color: $color-brand-3;
        font-size: 16px;
        font-weight: $font-bold;
        text-align: center;
        margin-bottom: $spacing;
    }

    .recover-pane {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        margin: 0;
        width: 100%;
        opacity: 0;
        contain: content;
        transform: translate3d(100%, 0, 0);
        transition: opacity 300ms ease-out, transform 300ms ease-out;

        &.is-active {
            position: relative;
            opacity: 1;
            transform: translate3d(0, 0, 0);
        }

        &.is-exiting {
            opacity: 0;
            transform: translate3d(-100%, 0, 0);
        }

        h2 {
            font-size: 16px;
            color: $color-brand-2;
            font-weight: $font-bold;
            text-align: center;
            margin-bottom: $spacing-half;
        }

        p {
            text-align: center;
        }

        .btn {
            width: 100%;
            margin: 5px 0;
        }
    }
}
