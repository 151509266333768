footer {
    width: 100%;
    padding: $spacing-double 0;
    background: #414142;
    text-align: left;
    color: #fff;

    .footer-container {
        width: 700px;
        max-width: 95%;
        margin: 0 auto;

        &__logo {
            margin-bottom: $spacing-half;
        }

        &__menu {
            list-style: none;
            padding: 0;
            margin: 0;

            & > li {
                height: 25px;
                line-height: 25px;
                padding: 0;
                margin: 0;
            }

            img {
                display: inline-block;
                margin-right: 5px;
            }

            a {
                display: inline-block;
                text-decoration: none;
                color: #b9b9b9;
                height: 25px;
                line-height: 25px;
            }

            a:hover, a:active {
                color: #fff;
            }
        }
    }

    h4 {
        color: #fff;
        font-size: 16px;
    }

    h2 {
        color: #fff;
        font-size: 16px;
        font-weight: $font-bold;
        letter-spacing: 2px;
    }

    p {
        color: #b9b9b9;
        margin: 0;
    }
}
