.confirm-box {
    max-width: 350px;
    padding: $spacing;
    text-align: center;

    &__info {
        margin-top: $spacing-half !important;
        text-align: center;
        font-style: italic;
        font-size: $font-size-base !important;
    }

    .popups__buttons {
        margin-top: 5px;
    }

    p {
        margin: 0;
        font-size: 17px;
    }
}
