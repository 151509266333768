.terms {
    font-size: 14px;
    margin-bottom: $spacing-double;

    h2 {
        font-size: 22px;
        font-weight: $font-bold;
        margin: 0 0 $spacing-half 0;
        padding: 0;
    }

    p {
        margin: 0 0 3px 0;
        padding: 0;
    }

    ol {
        li {
            padding-left: 10px;
            margin: 2px 0;
        }
    }
}
