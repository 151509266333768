.delivery-groups {
    padding-top: 80px;

    .dashboard {
        &__content {
            margin: $spacing-double 0 0 0;
            width: 100%;

            .btn {
                width: 200px;
                display: block;
                margin: $spacing auto 0 auto;
            }
        }
    }

    @include screen-sm() {
        padding-top: 150px;
    }

    @include screen-xs() {
        padding-top: 110px;
    }
}

.group {
    width: 100%;
    margin-bottom: $spacing-double;
    transition: max-height 0.5s;
    overflow: hidden;

    &__top-line {
        position: relative;
        display: flex;
        align-items: center;
        padding: 5px 0;
        margin-bottom: $spacing-half;
        border-bottom: 2px solid $gray2;
        background-color: #fff;
        z-index: 100;

        a {
            width: 20px;
            font-size: 20px;
            text-decoration: none;
        }

        h4 {
            font-size: 16px;
            padding: 0;
            margin: 0;
            width: calc(100% - 40px);
            cursor: pointer !important;
        }
    }

    .group-info {
        border: 2px solid #444;
        padding: 0 $spacing;

        &__title {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 2px solid $gray2;
            margin: $spacing-half 0 $spacing 0;

            h3 {
                color: #999;
            }

            .btn {
                margin: 0;
            }
        }

        &__cities {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            margin-bottom: $spacing;
        }

        &__city {
            width: 20%;
            text-align: center;

            .btn {
                position: relative;
                margin: 0;
                display: inline-block;
                width: auto;
                text-transform: none;
                background-color: transparent !important;
                background-size: 100% 200% !important;
                background-image: linear-gradient(to bottom, #fff 50%, #444 50%) !important;
                cursor: default;
            }

            .btn:hover, .btn:focus, .btn:active {
                background-position: 0 100% !important;
                outline: none !important;
                color: #fff;
            }

            a {
                color: #fff;
                position: absolute;
                right: 10px;
            }
        }

        &__header {
            width: 90%;
            margin: 0 auto $spacing auto;
            padding-bottom: $spacing-half;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 18px;
            font-weight: $font-bold;
            color: #999;
            border-bottom: 2px solid $gray2;
        }

        &__ranges {
            width: 90%;
            margin: 0 auto $spacing auto;

            .group-range {
                display: flex;
                align-items: center;
                justify-content: space-between;
                cursor: pointer;
                padding: 5px;
                transition: background-color 0.25s, color 0.25s;
            }

            .group-range:hover {
                background-color: #444;
                color: #fff;
            }
        }

        @include screen-md() {
            &__city {
                width: 25%;
            }
        }

        @include screen-sm() {
            &__city {
                width: 50%;
            }
        }

        @include screen-xs() {
            &__city {
                width: 100%;
            }
        }
    }

    .fa-caret-down {
        display: inline-block;
    }

    .fa-caret-up {
        display: none;
    }
}

.group.is-expanded {
    .fa-caret-down {
        display: none;
    }

    .fa-caret-up {
        display: inline-block;
    }

    .group__top-line {
        border: 0;
    }
}

.group-modal {
    width: 400px;
    max-width: 100%;
    border-radius: 0;
    border: 1px solid #636363;
    padding: $spacing-half;
    text-align: center;

    &__close {
        position: absolute;
        top: 0;
        right: 5px;
        font-size: 20px;
    }

    &__content {
        margin: $spacing 0;
    }

    &__delete {
        display: block;
        text-align: center;
    }

    h2 {
        font-size: 22px;
        font-weight: $font-bold;
        margin-bottom: 30px;
    }

    .form-group {
        margin-bottom: $spacing;
    }

    .rich-autocomplete {
        ul {
            max-height: 100px !important;
        }
    }
}
