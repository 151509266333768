.loader {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -50px 0 0 -50px;
    width: 100px;
    height: 100px;
    transition: width 150ms ease-in, height 150ms ease-in;

    &.is-hidden {
        width: 0;
        height: 0;
        overflow: hidden;
    }

    &-msg {
        color: #444;
        text-align: center;
        font-weight: 500;
        font-size: 16px;
    }

    .circular {
        height: 100px;
        position: relative;
        width: 100px;
        animation: rotate 2s linear infinite;
    }

    .path {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
        stroke: $color-brand-5;
        stroke-linecap: round;
        animation: dash 1.5s ease-in-out infinite;
    }
}

.loader-overlay {
    position: absolute;
    top: 0;
    left: 0;
    z-index: $zindex-overlay;
    width: 0;
    height: 0;
    opacity: 0;
    overflow: hidden;
    background: rgba(255, 255, 255, 0.9);
    transition: opacity 150ms ease-in 0ms, width 0ms ease-in 150ms, height 0ms ease-in 150ms;

    &.transparent {
        background: rgba(255, 255, 255, 0.4);

        .path {
            stroke: $color-brand-3;
        }
    }

    .loader {
        top: 30%;
        opacity: 0;
        transition: opacity 150ms ease-in;
    }
}

.is-loading {
    position: relative;

    .loader-overlay {
        width: 100%;
        height: 100%;
        opacity: 1;
        transition-duration: 250ms, 0ms, 0ms;
        transition-timing-function: ease-out, ease-out, ease-out;
        transition-delay: 0ms, 0ms, 0ms;
    }

    .loader {
        opacity: 1;
        transition-duration: 250ms;
        transition-timing-function: ease-out;
    }
}

// when "is-loading" class applied to body then only show the loader that is a direct child
body.is-loading {
    .loader-overlay {
        display: none;
    }

    > .loader-overlay {
        display: block;
        position: fixed;
    }
}


@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes dash {
    0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
        stroke: $color-brand-1;
    }

    16.5% {
        stroke: $color-brand-1;
    }

    33% {
        stroke: $color-brand-2;
    }

    50% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -35;
        stroke: $color-brand-3;
    }

    66.5% {
        stroke: $color-brand-4;
    }

    83% {
        stroke: $color-brand-5;
    }

    100% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -124;
        stroke: $color-brand-1;
    }
}
