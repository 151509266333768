.auth-card {
    width: 90%;
    margin: 0 auto;
    text-align: center;
    overflow: hidden !important;
    position: relative;

    &__tabs {
        text-align: center;
        margin-bottom: $spacing-double;
        display: flex;
        justify-content: space-around;

        a {
            padding: 0 $spacing;
            height: 50px;
            line-height: 50px;
            font-size: 20px;
            text-decoration: none;
            transition: border 0.25s;

            &:hover, &:active, &:focus, &.active {
                border-bottom: 5px solid $color-brand-4;
            }
        }
    }

    .auth-card-pane {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        margin: 0;
        width: 100%;
        opacity: 0;
        contain: content;
        transform: translate3d(100%, 0, 0);
        transition: opacity 300ms ease-out, transform 300ms ease-out;

        &.is-active {
            position: relative;
            opacity: 1;
            transform: translate3d(0, 0, 0);
        }

        &.is-exiting {
            opacity: 0;
            transform: translate3d(-100%, 0, 0);
        }

        .show-info {
            background-color: #f1c332;
            color: white;
            padding: 10px;
        }
    }

    .alert {
        display: none;
        text-align: left;
    }
}
