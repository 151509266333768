.history {
    padding: $spacing 0;
    text-align: center;

    h2 {
        padding: $spacing-half 0;
        font-weight: $font-bold;
    }

    .flex {
        display: flex;
        align-items: center;
        justify-content: space-around;
        margin-top: $spacing;
        margin-bottom: $spacing;

        img {
            width: 30%;
        }
    }

    .images {
        display: flex;
        align-items: center;
        justify-content: space-around;
        margin-top: $spacing;
        margin-bottom: $spacing;

        img {
            width: 47%;
        }
    }
}
