.event-details {
    padding: $spacing $spacing-half 0 $spacing-half;

    &__content {
        width: 1600px;
        max-width: 100%;
        margin: 0 auto;
        display: flex;
        max-height: 100vh;
        height: 100vh;
    }

    &__info {
        width: 300px;
    }

    &__users {
        text-align: center;
        width: calc(100% - 300px);

        .users-list {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }

        .btn {
            display: inline-block;
            width: 400px;
            max-width: 100%;
            margin: $spacing-double 0;
        }
    }

    &__fixed-top {
        transition: 0.25s;

        &.is-scrolled {
            position: fixed;
            top: 100px;

            @include screen-sm() {
                position: inherit;
            }
        }
    }

    h1 {
        width: 100%;
        text-align: center;
        font-weight: $font-bold;
        font-size: 25px;
        margin-bottom: $spacing;
    }

    @include screen-sm() {
        &__content {
            flex-direction: column;
        }

        &__info {
            margin: 0 auto;
        }

        &__users {
            width: 100%;
        }
    }
}
.pointer {
    width: 55px!important;
    margin-left: 15px;
}
