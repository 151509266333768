.about {
    padding: $spacing 0;
    text-align: center;

    h2 {
        padding: $spacing-half 0;
        font-weight: $font-bold;
    }

    p {
        margin-bottom: 0;
        font-size: $font-size-base*5/4;
    }

    .margin {
        margin-bottom: $spacing-double*2;
    }
}
