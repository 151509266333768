.btn {
    border-radius: 0;
    background-clip: padding-box !important;
    border: 0;
    transition-property: background, border-color, color;
    transition-duration: 150ms, 150ms, 150ms;
    transition-timing-function: ease-in, ease-in, ease-in;

    &:hover, &:focus {
        transition-timing-function: ease-out, ease-out, ease-out;
        transition-duration: 250ms, 250ms, 250ms;
    }
}

.btn-primary {
    background-color: transparent !important;
    color: #fff !important;
    background-size: 100% 200% !important;
    background-image: linear-gradient(to bottom, $color-brand-1 50%, darken($color-brand-1, 5%) 50%) !important;

    &:hover, &:focus, &:active {
        background-position: 0 100% !important;
        background-color: transparent !important;
        background-image: linear-gradient(to bottom, $color-brand-1 50%, darken($color-brand-1, 5%) 50%) !important;
        outline: none !important;
    }
}

.btn-primary2 {
    background-color: transparent !important;
    color: #fff !important;
    background-size: 100% 200% !important;
    background-image: linear-gradient(to bottom, $color-brand-6 50%, darken($color-brand-6, 5%) 50%) !important;

    &:hover, &:focus, &:active {
        background-position: 0 100% !important;
        background-color: transparent !important;
        background-image: linear-gradient(to bottom, $color-brand-6 50%, darken($color-brand-6, 5%) 50%) !important;
        outline: none !important;
    }
}

.btn-secondary {
    background-color: transparent !important;
    color: #fff !important;
    background-size: 100% 200% !important;
    background-image: linear-gradient(to bottom, $color-brand-2 50%, darken($color-brand-2, 5%) 50%) !important;

    &:hover, &:focus, &:active {
        background-position: 0 100% !important;
        background-color: transparent !important;
        background-image: linear-gradient(to bottom, $color-brand-2 50%, darken($color-brand-2, 5%) 50%) !important;
        outline: none !important;
    }
}


.btn-dark {
    background-color: transparent !important;
    color: #fff !important;
    background-size: 100% 200% !important;
    background-image: linear-gradient(to bottom, $green-dark 50%, darken($green-dark, 5%) 50%) !important;

    &:hover, &:focus, &:active {
        background-position: 0 100% !important;
        background-color: transparent !important;
        background-image: linear-gradient(to bottom, $green-dark 50%, darken($green-dark, 5%) 50%) !important;
        outline: none !important;
    }
}

.btn-bnw {
    border: 1px solid #444;
    background-color: transparent !important;
    background-size: 200% 92px !important;
    background-image: linear-gradient(to bottom, #fff 50%, #444 50%) !important;

    &:hover, &:focus, &:active {
        color: #fff;
        background-position: -200px -47px !important;
        background-color: transparent !important;
        background-image: linear-gradient(to bottom, #fff 50%, #444 50%) !important;
        outline: none !important;
    }
}

.btn-cancel {
    background-color: transparent !important;
    color: #fff !important;
    background-size: 100% 200%;
    background-image: linear-gradient(to bottom, $pink-red 50%, darken($pink-red, 5%) 50%);

    &:hover, &:focus, &:active {
        background-position: 0 100%;
        background-color: transparent;
        background-image: linear-gradient(to bottom, $pink-red 50%, darken($pink-red, 5%) 50%);
        outline: none !important;
    }
}

.btn-link {
    color: $color-link;
}


/*** Custom button classes ***/

.btn--xl {
    @extend .btn-lg;

    padding: 20px 60px;
}

.btn--responsive {
    white-space: normal !important;
    word-wrap: break-word;

    @include screen-sm {
        &.btn-lg, &.btn--xl {
            font-size: 16px;
        }
    }

    @include screen-xs {
        &.btn-lg, &.btn--xl {
            font-size: 14px;
        }
    }
}

.btn--outline {
    background: transparent;
    border: 2px solid currentColor;
}

.btn--social {
    position: relative;
    background: transparent;
    padding-left: 40px;
    padding-right: 40px;
    text-align: center;
    line-height: 24px;
    overflow: hidden;
    border: 1px solid currentColor;

    &.-google {
        color: #dd492b;

        &:hover {
            background: #dd492b;
            color: #fff;
        }
    }

    &.-facebook {
        color: #3b58a4;

        &:hover {
            background: #3b58a4;
            color: #fff;
        }
    }

    &.-linkedin {
        color: #0079bd;

        &:hover {
            background: #0079bd;
            color: #fff;
        }
    }

    &.-twitter {
        color: #00aced;

        &:hover {
            background: #00aced;
            color: #fff;
        }
    }

    &.-sms, &.-email {
        color: $color-brand-2;

        &:hover {
            background: $color-brand-2;
            color: #fff;
        }
    }

    i {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        padding: 10px;
        width: 40px;
        line-height: inherit;
        color: currentColor;
    }
}
