.noTransition * {
    transition-property: none !important;
    transform: none !important;
    animation: none !important;
}

html.remodal-is-locked, body.remodal-is-locked {
    overflow-y: auto !important;
    body {
        overflow-y: hidden;
    }
}

body {
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0 !important;
    overflow-x: hidden;
    width: 100vw;
    background-color: #fff;
}

b, strong {
    font-weight: $font-bold;
}

img {
    max-width: 100%;
    height: auto;
}

a, button {
    &::-moz-focus-inner {
        border: 0;
    }

    &:focus {
        outline: 0;
    }
}

article section {
    margin-bottom: $spacing-double;
}

section {
    figure {
        margin-bottom: $spacing;

        figcaption {
            display: block;
            margin-bottom: $spacing-half;
        }

        img {
            width: 100%;
        }
    }
}

.flatpickr-day.selected, .flatpickr-day.startRange, .flatpickr-day.endRange, .flatpickr-day.selected.inRange, .flatpickr-day.startRange.inRange, .flatpickr-day.endRange.inRange, .flatpickr-day.selected:focus, .flatpickr-day.startRange:focus, .flatpickr-day.endRange:focus, .flatpickr-day.selected:hover, .flatpickr-day.startRange:hover, .flatpickr-day.endRange:hover, .flatpickr-day.selected.prevMonthDay, .flatpickr-day.startRange.prevMonthDay, .flatpickr-day.endRange.prevMonthDay, .flatpickr-day.selected.nextMonthDay, .flatpickr-day.startRange.nextMonthDay, .flatpickr-day.endRange.nextMonthDay {
    background: $color-brand-1;
    border-color: lighten($color-brand-1, 5%);
}

.embed-responsive {
    margin-bottom: $spacing;
}


.input-group {
    .input-group-addon {
        border-color: $color-input-border;
        background: transparent;
        color: $color-font-light;

        &:first-child {
            border-right-color: transparent;
            border-radius: $border-radius-base 0 0 $border-radius-base;

            & + .form-control {
                border-left-color: transparent;
                padding-left: 0;
            }
        }

        &:last-child {
            border-left-color: transparent;
            border-radius: 0 $border-radius-base $border-radius-base 0;
        }
    }

    .addon-left {
        border-right-color: transparent;
        padding-right: 0;
    }


    .input-group-btn {
        margin: 0;
        padding: 0;

        .form-control {
            padding: 0 !important;
            margin: 0 !important;
        }

        .btn {
            padding: 11px 30px;
            margin: 0;
        }
    }
}


.form-group.label-floating {
    border: 2px solid $gray2;
    margin: 0 0 $spacing-half 0;
    padding: 0;
    text-align: left;

    .form-control {
        background-image: none !important;
        padding: 20px 10px 5px 10px;
        margin: 0;
        line-height: 16px;
        font-size: 14px;
        height: 40px;
    }

    textarea.form-control {
        height: 100px !important;
    }

    label.control-label {
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 0 10px;
    }

    &.is-focused {
        .form-control {
            background-image: none !important;
        }

        label.control-label {
            color: $color-brand-1 !important;
            top: -13px !important;
        }
    }

    &:not(.is-empty) {
        label.control-label {
            color: $color-brand-1 !important;
            top: -13px !important;
        }
    }

    &.has-error.is-focused, &.has-warning.is-focused, &.has-danger.is-focused {
        label.control-label {
            color: #f44336 !important;
        }

        .help-block {
            display: inline-block;
            margin-top: 5px;
            background-color: $color-brand-1;
            color: #fff;
            z-index: 100;
            padding: 10px 20px 5px 20px;
        }
    }

    &.has-feedback, &.has-action {
        .form-control {
            padding: 20px 40px 5px 10px;
        }

        .form-control-feedback, .form-control-action {
            display: block;
            position: absolute;
            right: 0;
            top: 0;
            height: 40px;
            width: 40px;
            line-height: 40px;
            font-size: 20px;
            text-align: center;
        }
    }

    &.has-flag {
        .form-control {
            padding: 20px 10px 5px 75px;
        }

        .control-label {
            padding: 0 10px 0 75px;
        }

        //.rich-autocomplete {
        //    position: relative;
        //    top: 0;
        //    width: 100%;
        //}

        & > .sprite-container {
            width: 65px;
            height: 40px;
            margin-right: $spacing-half;
            display: block;
            position: absolute;
            left: 27px;
            top: 17px;

            .sprite {
                transform: scale(0.866, 0.866) translate(-50%, -50%);
            }
        }
    }
}

.line-break {
    margin-bottom: $spacing;
    padding-bottom: $spacing;
    border-bottom: 2px solid $gray2;
}


/** Base Remodal styles **/
.remodal-overlay {
    background: rgba($green-dark, 0.7);
    contain: content;
}

.remodal {
    background: $color-modal-bg;
    color: $color-font-base;
    text-align: left;
    contain: content;
    box-shadow: 0 5px 25px rgba(0, 0, 0, 0.2);
}

.remodal-wrapper {
    overflow-x: hidden;
}

.remodal-close {
    left: auto;
    right: 0;
}

/** General use classes **/
.nopadding {
    padding: 0;
}

.nomargin {
    margin: 0;
}


.lg-hidden {
    @include screen-lg() {
        display: none !important;
    }
}

.md-hidden {
    @include screen-md() {
        display: none !important;
    }
}

.sm-hidden {
    @include screen-sm() {
        display: none !important;
    }
}

.xs-hidden {
    @include screen-xs() {
        display: none !important;
    }
}

.overflow-hidden {
    overflow: hidden;
}

.dtp {
    z-index: 15000;
}

.break-word {
    word-wrap: break-word !important;
}

body.is-loading > .loader-overlay {
    z-index: 10000;
}

.select-box {
    width: 100%;
    padding: 0;
    margin: 0;
    position: relative;
    cursor: pointer;

    ul {
        overflow-y: hidden;
        overflow-x: hidden;
        display: block;
        padding: 0;
        margin: 0;
        width: 100%;
        list-style: none;
        max-height: 0;
        position: absolute;
        top: 40px;
        transition: max-height 0.25s ease-in-out;
        z-index: 100;
        border: 2px solid $gray2;
        background-color: $gray-for-back;

        & > li {
            padding: 0;
            margin: 0;
            width: 100%;
        }

        a {
            display: inline-block;
            width: 100%;
            color: #b5aeae;
            text-decoration: none;
            padding: 0 $spacing-half;
            height: 30px;
            line-height: 30px;
            font-size: 18px;
            transition: color 0.5s;
            font-weight: $font-bold;
        }

        a:hover, a:active, a:focus, a.active {
            color: $color-brand-1;
        }
    }

    &__display {
        position: relative;
        width: 100%;
        height: 40px;
        line-height: 55px;
        border: 0;
        padding: 0 0 0 $spacing-half;
        margin: 0;
        font-size: 14px;
        cursor: pointer;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        transition: border-radius 0.25s ease-in-out;

        &::after {
            color: $color-font-base;
            font-size: 20px;
            line-height: 40px;
            font-weight: bold;
            font-family: FontAwesome;
            content: '\f107';
            position: absolute;
            top: 0;
            right: 15px;
        }
    }

    &.is-opened {
        .select-box__display {
            border-radius: 20px 20px 0 0;
        }

        .select-box__display::after {
            font-family: FontAwesome;
            content: '\f106';
        }

        ul {
            max-height: 250px;
            padding: $spacing-half 0;
        }
    }
}

.checkbox input[type=checkbox]:checked + .checkbox-material .check, label.checkbox-inline input[type=checkbox]:checked + .checkbox-material .check {
    color: $color-brand-1;
    border-color: $color-brand-1;
}

.checkbox input[type=checkbox]:checked + .checkbox-material .check:before, label.checkbox-inline input[type=checkbox]:checked + .checkbox-material .check:before {
    color: $color-brand-1;
}

.radio input[type=radio]:checked ~ .check, label.radio-inline input[type=radio]:checked ~ .check, .radio .check, label.radio-inline .check {
    background-color: $color-brand-1;
}

.radio input[type=radio]:checked ~ .circle, label.radio-inline input[type=radio]:checked ~ .circle {
    border-color: $color-brand-1;
}

.radio label, label.radio-inline {
    padding-left: 30px;
}

input[type=date]:required:invalid::-webkit-datetime-edit {
    color: #d9d9d9;
}

input[type=date]:required:valid::-webkit-datetime-edit {
    color: #d9d9d9;
}

input[type=date]:focus::-webkit-datetime-edit {
    color: #d9d9d9 !important;
}


.page-content {
    margin-top: 70px;

    @include screen-sm() {
        margin-top: 100px;
    }
}

.contained {
    width: 950px !important;
    max-width: calc(100% - 30px);
    margin: 0 auto;
}

.dashboard {
    width: 1070px;
    max-width: 100%;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: flex-start;
    align-items: flex-start;
    max-height: 100vh;
    height: 100vh;

    &__menu {
        width: 200px;
        position: relative;

        ul {
            padding: 0;
            margin: 0;
            list-style: none;
        }

        li {
            margin: 0;
            padding: 0;
        }

        a {
            display: block;
            width: 85%;
            margin: 0 0 $spacing-half 0;
            padding: 5px 0;
            font-size: 18px;
            font-weight: $font-bold;
            text-align: center;
            text-decoration: none;
            border-right: 7px solid $gray2;
            background-color: transparent;
            background-size: 200% 100%;
            background-image: linear-gradient(to right, #fff 50%, #444 50%);
            transition: 0.5s;
        }

        a:hover, a:active, a:focus, a.active {
            color: #fff;
            background-position: 100% 0;
            border-right: 7px solid #111;
        }

        @include screen-xs() {
            li {
                margin: 6px;
            }
        }
        @include screen-sm() {
            li {
                margin: 6px;
            }
        }
    }

    &__fixed-top {
        transition: 0.25s;

        &.is-scrolled {
            position: fixed;
            top: 150px;
            width: inherit;

            @include screen-sm() {
                position: relative;
                top: 0;
            }
        }

        @include screen-xs() {
            li {
                display: inline-block;
                float: left;
            }

            a {
                display: inline-block;
                float: left;
            }
        }

        @include screen-sm() {
            li {
                display: inline-block;
                float: left;
            }

            a {
                display: inline-block;
                float: left;
            }
        }
    }

    &__widget {
        width: 100%;
        margin: $spacing-half 0 $spacing-half 250px;

        @include screen-md() {
            margin: $spacing-half;
        }
    }

    &__content {
        width: calc(100% - 250px);

        h1 {
            font-size: 20px;
            text-align: center;
            background-color: $gray1;
            font-weight: $font-bold;
            padding: 7px 0;
            margin: 0 0 25px 0;
        }
    }

    @include screen-sm() {
        flex-direction: column;

        &__menu {
            width: 35%;

            ul {
                display: grid;
                justify-content: center;
                margin-bottom: $spacing;
            }

            a {
                display: inline-block;
                width: auto;
                margin: 0 $spacing-half;
                padding: 0 5px;
                background-size: 100% 200%;
                background-image: linear-gradient(to bottom, #fff 50%, #444 50%);
                border-right: 0;
                border-bottom: 5px solid $gray2;
            }

            a:hover, a:active, a:focus, a.active {
                background-position: 0 100%;
                border-bottom: 5px solid #111;
                border-right: 0;
            }
        }

        &__content {
            width: 100%;
        }
    }
}

/** Rich Autocomplete style **/
.rich-autocomplete {
    &-list {
        padding: $spacing-half 0;
        position: absolute;
        background: $color-brand-1;
        color: #666;
        width: 100%;
        z-index: $zindex-dropdown;
        max-height: 170px !important;
        overflow-y: auto;

        &-item, &-item-empty {
            list-style-type: none;
            cursor: pointer;
            color: #fff;
            text-decoration: none;
            margin: 0;
            padding: 0 $spacing-half;
            height: 30px;
            line-height: 30px;
            font-size: 18px;
            transition: color 0.5s;
            font-weight: $font-bold;
        }

        &-item:hover, &-item.highlighted {
            color: $gray2;
        }

        &-item:last-child {
            border: 0;
        }

        &-item > p {
            margin: 0;
        }
    }

    &-input {
        label {
            color: $color-font-light;
        }
    }
}
