.cities-popup {
    max-width: 900px;

    &__close {
        position: absolute;
        top: 0;
        right: 5px;
        font-size: 20px;
    }

    &__title {
        width: 100%;
        color: $color-brand-2;
        font-weight: $font-bold;
        font-size: 25px;
        text-align: center;
    }

    &__content {
        display: flex;
        width: 100%;
        margin-top: $spacing-double;
        flex-wrap: wrap;
    }
}

.continent-column {
    width: calc(25% - 40px);
    margin-right: $spacing-double;

    &__title {
        padding-bottom: $spacing-half;
        border-bottom: 3px solid $color-brand-2;
        color: $color-font-heading;
        font-weight: $font-bold;
        text-align: center;
    }

    &__list {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    &:last-child {
        margin-right: 0;
    }

    @include screen-sm() {
        width: calc(50% - 40px);
        margin-bottom: $spacing;

        &:nth-child(even) {
            margin-right: 0;
        }
    }
}

.continent-city {
    position: relative;
    width: 100%;
    margin: $spacing-half 0;
    padding: $spacing-half;
    text-align: center;
    cursor: pointer;
    transition: all 0.25s ease;
    background-color: transparent;
    background-size: 100% 200%;
    background-image: linear-gradient(to bottom, $color-modal-bg 50%, $color-brand-2 50%);

    &:hover, &.active {
        background-position: 0 100%;

        & a {
            color: white;
        }

        & .continent-city__remove {
            opacity: 1;
        }
    }

    & a {
        text-decoration: none;
    }

    &__remove {
        position: absolute;
        right: $spacing-half;
        top: 35%;
        color: white;
        opacity: 0;
    }
}

.city-adder {
    position: relative;
    margin: $spacing-half 0 30px 0;
    height: $spacing;

    & * {
        transition: all 0.2s ease;
    }

    &__overlay {
        display: flex;
        justify-content: center;
        align-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        font-size: 20px;
        cursor: pointer;

        & * {
            pointer-events: none;
        }
    }

    .rich-autocomplete {
        ul {
            opacity: 0;
            overflow-x: hidden;
            max-height: 100px !important;

            li {
                height: auto !important;
                margin: 5px 0;
            }

            li:first-child {
                margin: 0 0 5px 0;
            }

            li:last-child {
                margin: 5px 0 0 0;
            }
        }

        p {
            margin: 0;
            padding: 0;
            line-height: 15px !important;
            font-size: 15px !important;
            word-wrap: break-word !important;
        }
    }

    &__select {
        opacity: 0;
        padding: $spacing-half $spacing;
        width: 100%;
        text-align-last: center;
    }

    &__input {
        margin: 0;
        padding: $spacing-half $spacing;
        width: 100%;
        opacity: 0;
        text-align: center;
    }

    & button {
        opacity: 0;
        width: 100%;
        display: none;
    }

    &--open {
        height: auto;

        & .city-adder__overlay {
            opacity: 0;
            z-index: -1;
        }

        & .city-adder__select {
            opacity: 1;
        }

        & .city-adder__input, button:not([disabled]) {
            opacity: 1;
        }

        & button {
            display: inherit;
        }

        & .rich-autocomplete ul {
            opacity: 1;
        }
    }
}
